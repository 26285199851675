import { Controller } from "stimulus"

export default class extends Controller {
  static values = {parentId: Number}
  initialize() {
  }

  connect() {
    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this;
    console.log("Connecting reactions controller...")
  }

  disconnect() {
    console.log("Disconnecting reactions controller...")
  }

  dispatchDirty() {
    return;
    var itemId = this.data.get("item-id");
    var parentId = this.element.dataset.itemParentId;
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("item-dirty", true, true, {item: itemId, parent: parentId})
    this.element.dispatchEvent(event)
  }

  dirty() {
    return;
    console.log("I'm dirty!")
    this.refresh();
  }

  reactionClick() {
    var itemId = this.data.get("item-id");
    var label = this.data.get("label");
    var starred = this.data.get("starred");
    var badged = this.data.get("badged");
    var that = this;

    console.log("starred..." + starred);
    console.log("badged..." + badged);


    if (badged) {
      var badge = $(this.element).find('span.mdl-badge')[0];
      var badge_data = badge.dataset.badge;
    }
    
    $.ajax({
      url: '/jobs/' + itemId + '/' + (starred=="true" ? 'unstar' : 'star') + '?label=' + label,
      method: 'post',
      success: function(result) {
        // that.dispatchDirty(itemId);
    }});

    if (starred == "true") {
      this.data.set("starred", false);
      if (badged) {
        badge.dataset.badge = Number(badge.dataset.badge) - 1
        if (badge.dataset.badge == "0"){
          $(badge).addClass("inactive");
        }
      }
    } else {
      this.data.set("starred", true);
      if (badged) {
        badge.dataset.badge = Number(badge.dataset.badge) + 1
        $(badge).removeClass("inactive");
      }
    }

  }
}
