import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    console.log("Initializing toast controller")
  }

  connect() {
    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this;
  }

  showToast(message) {
    var snackbarContainer = document.querySelector('#toast');
    var data = {message: message};
    snackbarContainer.MaterialSnackbar.showSnackbar(data);
  }

  disconnect() {
    console.log("Disconnecting toast controller...")
  }

  close(event) {
    console.log("Closing toast based on input")
  }

}
