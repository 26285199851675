import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
  }

  connect() {
    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this;
    console.log("Connecting note...")
  }

  disconnect() {
    console.log("Disconnecting note...")
  }

  dispatchDirty() {
    // var itemId = this.data.get("item-id");
    // const event = document.createEvent("CustomEvent")
    // event.initCustomEvent("item-dirty", true, true, {item: itemId})
    // this.element.dispatchEvent(event)
  }

  dirty() {
    return;
    console.log("I'm dirty!")
    this.dispatchDirty();
    this.refresh();
  }

  refresh() {
    var noteId = this.data.get("id");
    var that = this;
    $.ajax({
      url: '/work_logs/' + noteId + '?only=decorators',
      success: function(result) {
        console.log($(this.element));
        $(that.element).find('.decorators').html(result);
        hookupJavascript();
    }});
  }

}
