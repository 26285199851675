import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    console.log("Initializing job-form controller")
  }

  connect() {
    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this;
    console.log("Connecting job form with parent " + this.data.get("parent-id") + "...")
  }

  disconnect() {
    console.log("Disconnecting job form...")
  }

  dispatchDirty() {
    return;
    var itemId = this.data.get("parent-id");
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("item-dirty", true, true, { item: itemId })
    this.element.dispatchEvent(event)
  }

  dirty() {
    return;
    console.log("Job form is dirty!")
    this.refresh();
  }

  showItemForm() {
    console.log("Showing item form")
    $(this.element).find(".addItemCTA").hide()
    var form = $(this.element).find(".itemForm");
    form.show()
    form.find('textarea')[0].focus();
  }

  hideItemForm() {
    console.log("Hiding item form")
    $(this.element).find(".addItemCTA").show()
    $(this.element).find(".itemForm").hide()
    this.resetForm();
  }

  resetForm() {
    var form = $(this.element).find('form')[0];
    form.reset();
    this.checkSubitems();
    let additionalFields = $(this.element).find(".collapse.show")
    if (!!form.job_template)
      form.job_template.selectedIndex = 0
    additionalFields.collapse("hide")
  }

  checkSubitems() {
    var form = $(this.element).find('form');
    var content = form.find('textarea')[0].value
    var has_subitems = (content.split("\n").length > 2)
    if (has_subitems) {
      $('#create_subitems_option').show()
    } else {
      $('#create_subitems_option').hide()
    }
  }

  postSuccess(event) {
    console.log("in postSuccess")
    this.resetForm();
    showToast("Added");

    // this.dispatchDirty();

    hookupJavascript();
    this.showItemForm();
  }

  switchParent(event) {
    event.preventDefault()
    let target = event.currentTarget
    let newParentId = target.dataset.newParentId
    let parentInput = this.element.querySelector("#job_parent_id")
    let parentTitle = target.parentElement.querySelector(".parent-title")
    let oldParentId = this.data.get('parent-id')

    if (parentInput.value == oldParentId) {
      parentInput.value = newParentId
      parentTitle.innerText = "current folder."
      target.innerText = `Add to ${this.data.get('parent-name')} instead`
    } else {
      parentInput.value = oldParentId
      parentTitle.innerText = this.data.get('parent-name') + "."
      target.innerText = "Add to current folder instead"
    }
  }

  // refresh() {
  //   var itemId = this.data.get("id");
  //   var partial = this.data.get("partial");
  //   var that = this;
  //   $.ajax({
  //     url: '/jobs/' + itemId + '?partial=' + partial,
  //     success: function(result) {
  //       console.log($(this.element));
  //       $(that.element).replaceWith(result);
  //       hookupJavascript();
  //   }});
  //}
}
