import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source"];

  copy(event) {
    event.preventDefault();

    let copiedText = "";

    if (this.hasSourceTarget) {
      copiedText = this.sourceTarget.value;
      navigator.clipboard.writeText(copiedText);
    } else if (this.data.has("value")) { // Corrected method
      copiedText = this.data.get("value");
      this.copyFromDataAttribute(copiedText);
    } else {
      console.warn("No source or data-value found for copying.");
      return;
    }

    this.showToast(`Copied: ${copiedText}`);
  }

  copyFromDataAttribute(value) {
    const textarea = document.createElement("textarea");
    textarea.value = value;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  }

  showToast(message) {
    const toastElement = document.querySelector('#toast');

    // Access the ToastController instance and call the showToast method
    if (toastElement && toastElement.toast) {
      toastElement.toast.showToast(message);
    }
  }
}
