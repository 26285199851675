document.addEventListener("turbo:load", () => {

  hookupHorizontalScroll = function() {
    // Drag scroll behavior for navigation tabs for desktop browsers
    let x,left,down,isDragging,horizontalScrollTarget;

    $(".horizontal-scroll").mousedown(function(e){
        e.preventDefault();
        horizontalScrollTarget = e.target.closest('.horizontal-scroll');
        down = true;
        x = e.pageX;
        left = $(this).scrollLeft();
    });

    $("body").mousemove(function(e){
      if(down){
        isDragging = true;
        let newX = e.pageX;
        let newY = e.pageY;
        $(horizontalScrollTarget).scrollLeft(left - newX + x);
      }
    });

    $("body").mouseup(function(e){
      down = false;
    });

    $("body").click(function(e) {
      if (isDragging) {
        event.preventDefault();
        isDragging = false;
      }
    });
  }
  hookupHorizontalScroll();
})
