import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this;
  }

  save(event) {
    event.target.form.requestSubmit()
  }
}

