import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
  }

  connect() {
    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this;
    console.log("Connecting link controller...")
    var that = this;
    setTimeout(function() {
      // console.log("Calling showHeroImage for...")
      // console.log(that)
      that.showHeroImage();
    }, 1000)
  }

  follow() {
    var url = this.data.get("url")
    location.href = url;
  }

  preview() {
    console.log("Setting up URL preview")

    var urlId = this.data.get("id")
    var url = this.data.get("url")

    var pane = $('#url-preview')
    var header = $('#preview-content-header-container')

    pane.addClass('active');
    // header.html(fetch("/links/" + urlId + "?partial=preview-header"))

    $.ajax({
      url: "/links/" + urlId + "?partial=preview-header",
      success: function(result) {
        var header = $('#preview-content-header-container')
        $(header).html(result);
    }});

    $('#right-drawer-iframe').attr('src', this.data.get("url"))
  }

  // closePreview() {
  //   $('#url-preview').removeClass('active');
  // }

  // this should be called iteratively until all images are loaded,
  // rather than assume 1s is the right amount of time to wait
  showHeroImage() {
    var heroImages = $(this.element).find('.hero-image-candidate')
    if (heroImages.length == 0)
      return

    var heroImage = $(this.element).find('img.hero-image')
    if (heroImage.length == 0) {
      heroImages.remove()
      return
    }

    var minHW = 250  // ignore small images
    var goodHW = 400 // pick first image that is at least goodHW x goodHW

    var bigH = 0
    var bigW = 0
    var bigImage = null

    for (var i = 0; i < heroImages.length; i++) {
      var image = heroImages[i]
      $(image).css("display", "none")
      console.log("i: " + i + " height: " + image.height + " width: " + image.width + " " + image.src)
      if (image.width > minHW && image.height > minHW &&
          image.width * image.height > bigW * bigH) {

        // sanity check against very tall or very wide images
        if (image.width / image.height > 3.0 || image.height / image.width > 3.0) continue;

        bigH = image.height
        bigW = image.width
        bigImage = image

      }
      if (bigH > goodHW && bigW > goodHW) break; // good enough
      // if (i >= 30) { break; } // give up
    }
    console.log("Found hero image:")
    console.log(bigImage)
    // $(bigImage).css("visibility", "visible")

    if (bigImage != null) {
      heroImage.css("display", "inline")
      heroImage.css("max-width", heroImage.parent().width())
      heroImage[0].src = bigImage.src

      var urlId = this.data.get("id")
      $.ajax({
        url: "/links/" + urlId + "/set_guessed_hero_image?url=" + encodeURIComponent(bigImage.src),
        success: function(result) {
          var header = $('#preview-content-header-container')
          $(header).html(result);
      }});
    }

    heroImages.remove()
  }

  disconnect() {
    console.log("Disconnecting link controller...")
  }

}
