/* eslint no-console:0 */

require("@rails/ujs").start()
require("@fancyapps/fancybox/dist/jquery.fancybox.min.js")
require("@rails/activestorage").start()
require("channels")
require("stimulus-controllers")

import "@hotwired/turbo-rails"

import "bootstrap"
import "../stylesheets/application"

import "./drawer-right"
import "./extensions"
import "./collections"
import "./file-upload"
import "./job-search"
import "./job_actions"
import "./job_form"
import "./horizontal_scroll"
import "./modal"
import "./nav-tabs"
import "./work_logs_form"
import "./localize_time"
import "./sortable"
import "./textarea"
import "./address_autocomplete"
import "./judo"

require("jquery-ui/ui/widget")
require("jquery-ui/ui/widgets/sortable")
require("jquery-ui/ui/widgets/droppable")

require('material-design-lite/material')

require('jquery-ui/ui/effects/effect-blind')
require("semantic-ui-api/api.min.js")
require("semantic-ui-search/search.min.js")


import { StreamActions } from "@hotwired/turbo"

// Trying to turn off turbo on links everywhere due to broken streams after redirect
// It's off for links in the application.html body tag
Turbo.setFormMode("off");

StreamActions.toast = function() {
  const text = this.templateContent.textContent
  $('#toast')[0].toast.showToast(text)
}

document.addEventListener("turbo:before-stream-render", (event) => {
  const turboStreamElement = event.target;
  const action = turboStreamElement.getAttribute('action');
  console.log("turbo:before-stream-render Action: " + action);

  const newContent = turboStreamElement.querySelector('template').content.firstElementChild;
  if (newContent) {
    const uniqueId = newContent.getAttribute('id');
    console.log("Unique ID: " + uniqueId);

    if (action === 'replace') return;
    if (uniqueId) {
      const existingElement = document.getElementById(uniqueId);

      if (existingElement) {
        // Prevent duplicate append
        console.log("Preventing duplicate " + action);
        event.preventDefault();
      }
    }
  }
});

window.jQuery = $;
window.$ = $;

document.addEventListener('DOMContentLoaded', function () {
  const tableElement = document.querySelector('.mdl-data-table');
  if (tableElement) {
    new Tablesort(tableElement);
  }
});