document.addEventListener("turbo:load", () => {
  $("[data-toggle='tooltip']").tooltip()
  $("[data-toggle='popover']").popover()
  componentHandler.upgradeDom();
});

document.addEventListener("turbo:load", () => {
  CSRFToken = () => ($('[name="csrf-token"]')[0] || {}).content

  CSRFHeader = () => {
    const options = {
      headers: new Headers({ "X-CSRF-Token": CSRFToken() })
    }
    return options
  }
});

document.addEventListener("turbo:load", () => {
  hookupJavascript = () => {
    // required for material design lite tabs to function in sidebar
    componentHandler.upgradeAllRegistered();

    hookupHorizontalScroll();
    hookupTextareaAutosize();
    hookupSort();
  }
  hookupJavascript();
});

showToast = function(message) {
  $('#toast')[0].toast.showToast(message)
}
