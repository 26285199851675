document.addEventListener("turbo:load", () => {
  $(document).on('click', 'a.mdl-tabs__tab', function() {
    hookupJavascript();
  });

  $('.mdl-layout__obfuscator-right').click(function(){
  //   if($('.mdl-layout__drawer-right').hasClass('active')){
      $('.mdl-layout__drawer-right').removeClass('active');
      $('.mdl-layout__drawer-right').html('');
  //   }
  //   else{
  //     $('.mdl-layout__drawer-right').addClass('active');
  //   }
  });
});
