document.addEventListener("turbo:load", () => {
  $(document).on('ajax:complete', '.action-refresh', function(event) {
    el = event.target.closest('.refreshable')
    // el.item.dispatchDirty();
  })

  $(document).on('ajax:send', '.ajax-deletable .delete-btn, .ajax-deletable .dropdown-delete', function(event) {
    event.target.closest('.ajax-deletable').classList.add('animate')
  })
  $(document).on('ajax:complete', '.ajax-deletable .delete-btn, .ajax-deletable .dropdown-delete', function(event) {
    event.target.closest('.ajax-deletable').remove();
    showToast("Deleted");
  })
});
