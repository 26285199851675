// app/javascript/controllers/turbo_subscription_controller.js
// This controller is used to prevent duplicate Turbo Streams from being subscribed to
// and to handle automatic WebSocket reconnection.

import { Controller } from "stimulus";

export default class extends Controller {
  static values = { streamId: String }

  connect() {
    // Initialize the global object if it doesn't exist
    window.activeSubscriptions = window.activeSubscriptions || {};

    const streamId = this.streamIdValue;

    // Check if the subscription already exists
    if (window.activeSubscriptions[streamId]) {
      this.element.remove(); // Prevent duplicate subscription
    } else {
      // Mark the stream as subscribed
      window.activeSubscriptions[streamId] = true;

      // Setup WebSocket connection
      this.setupWebSocket();
    }
  }

  setupWebSocket() {
    this.subscription = new WebSocket(this.webSocketURL());

    this.subscription.onclose = () => {
      console.log("WebSocket closed, attempting to reconnect...");
      setTimeout(() => {
        this.setupWebSocket();
      }, 1000); // Attempt to reconnect every second
    }

    this.subscription.onopen = () => {
      console.log("WebSocket connection established");
    }

    this.subscription.onerror = (error) => {
      console.error("WebSocket error:", error);
    }
  }

  webSocketURL() {
    const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
    return `${protocol}://${window.location.host}/cable?stream_id=${this.streamIdValue}`;
  }
}
