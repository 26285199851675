document.addEventListener("turbo:load", () => {
  $(document).on('ajax:send', '.remote-load-collection', function(event) {
    //$('#workspace').html('<div class = "mdl-spinner mdl-js-spinner is-active"></div>')
    $('.jobrow').remove();
    $('#workspace .card').html('<div class="text-center mt-4 mb-4"><div class="mdl-spinner mdl-js-spinner is-active"></div></div>')
    $('#workspace-right .card').html('<div class="text-center mt-4 mb-4"><div class="mdl-spinner mdl-js-spinner is-active"></div></div>')
    componentHandler.upgradeAllRegistered();
  });

  $(document).on('ajax:complete', '.remote-load-collection', function(event) {
    $('#workspace').html(event.detail[0].responseText);
    if ($(window).width() < 844) {
      $('#workspace')[0].scrollIntoView({behavior: 'smooth', alignto: 'top'});
    }
    hookupJavascript();
    componentHandler.upgradeAllRegistered();
  });
});
