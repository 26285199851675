$(document).ready(() => {
  $(document).on('ajax:complete', '.work_log_form', function (event) {
    let details = event.detail[0]
    if (details.status == 200) {
      // let row = details.responseText
      // let logs = document.querySelector("#notes")
      // logs.insertAdjacentHTML("afterbegin", row)
      hookupTextareaAutosize();
      resetForm(event.target)
    } else {
      console.log("Error: work log creation failed")
    }
  });

  function resetForm(form) {
    form.new_work_log_body.value = null
  }

});
