Date.prototype.toLogFormat = function() {
  let formattedTime = this.toLocaleString("default", {
        hour: '2-digit',
        minute: '2-digit',
      }),
      timeZone = this.toLocaleString("default", {
        timeZoneName: 'short'
      }).split(' ').pop(),
      formattedDate = this.toLocaleString("default", {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      })

  return `${formattedDate} at ${formattedTime} ${timeZone}`
}
