import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    console.log("Initializing move controller")
  }

  connect() {
    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this;
  }

  disconnect() {
    console.log("Disconnecting move controller...")
  }

  move() {
    var itemId = this.data.get("item-id");
    console.log("Item id is " + itemId)
    const event = document.createEvent("CustomEvent")
    // event.initCustomEvent("item-dirty", true, true, {item: itemId})
    this.element.dispatchEvent(event)

    var destinationId = this.data.get("destination-id");
    const destEvent = document.createEvent("CustomEvent")
    // destEvent.initCustomEvent("item-dirty", true, true, {item: destinationId})
    this.element.dispatchEvent(destEvent)

    $('#job_' + itemId + '.row').remove();
  }

}
