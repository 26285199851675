document.addEventListener("turbo:load", function() {
  function initializeAutocomplete(addressField) {
    const autocomplete = new google.maps.places.Autocomplete(addressField, {
      types: ['geocode'],
      componentRestrictions: { country: 'us' } // Restrict to a specific country if needed
    });

    // Prevent form submission on enter key
    addressField.addEventListener('keydown', function(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    });

    // Listen for the place_changed event to get the details of the selected place
    autocomplete.addListener('place_changed', function() {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        addressField.value = place.formatted_address; // Set the value of the input field to the formatted address
        // addressField.form.commit.click();
        console.log("Selected place: ", place);
      }
    });
  }

  // Initialize autocomplete for existing fields
  const addressFields = document.querySelectorAll('.address_field');
  addressFields.forEach(initializeAutocomplete);

  // Use MutationObserver to watch for new address fields
  const observer = new MutationObserver(function(mutationsList) {
    for (let mutation of mutationsList) {
      if (mutation.type === 'childList') {
        mutation.addedNodes.forEach(node => {
          if (node.nodeType === 1) { // Element node
            const newAddressFields = node.querySelectorAll('.address_field');
            newAddressFields.forEach(initializeAutocomplete);
          }
        });
      }
    }
  });

  // Start observing the document body for added nodes
  observer.observe(document.body, { childList: true, subtree: true });
});