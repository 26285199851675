// documentation: https://api.jqueryui.com/sortable/

document.addEventListener("turbo:load", () => {
  hookupSort = function() {
    $(".sortable").sortable({
      update: function(e, ui) {
        $.ajax({
          url: $(this).data("url"),
          type: "PATCH",
          data: $(this).sortable('serialize'),
        })},
      items: ".sortable-item",
      handle: '.handle',
      placeholder: "sortable-placeholder"
    });
    $(".collection").droppable({
      accept: ".sortable-job-item",
      tolerance: "pointer",
      classes: {
        "ui-droppable-hover": "droppable-hover"
      },
      drop: function( event, ui ) {
        console.log( ui.draggable );
        var source = $( ui.draggable ).data("job-id");
        var target = $( this ).data("job-id");
        var template = $( this ).data("workspace-templates") == true;
        console.log("dropped " + source + " on " + target + " with is_template of " + template);
        $.ajax({
          url: "/jobs/" + source,
          type: "PATCH",
          data: {job: {parent_id: target, is_template: template, move_to: "top"}},
        })
        destinationBadge = $( this ).find( ".badge" );
        count = Number( destinationBadge[0].innerHTML ) + 1;
        destinationBadge[0].innerHTML = count;
        count == 0 ? destinationBadge.hide() : destinationBadge.show();

        sourceBadge = $( ".collection.active" ).find( ".badge" );
        count = Number( sourceBadge[0].innerHTML ) - 1;
        sourceBadge[0].innerHTML = count;
        count == 0 ? sourceBadge.hide() : sourceBadge.show();

        $( ui.draggable ).remove();
      }
    })
    $('.collection > .badge').each(function(i) {
      count = Number( this.innerHTML );
      count == 0 ? $(this).hide() : $(this).show();
    })
  };

  hookupSort();
});
