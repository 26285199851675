import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    clientId: String,
    scopes: Array,
    callbackUrl: String
  }

  initialize() {
  }

  connect() {
    const isAuthorized = document.querySelector("[data-authorized]")


    if (isAuthorized) {
      this.element.remove()
    }

    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this;

    this.client = google.accounts.oauth2.initCodeClient({
      client_id: this.data.get('client-id'),
      scope: this.data.get('scopes'),
      ux_mode: 'popup',
      callback: this.authCallback.bind(this)
    })
  }

  auth(evt) {
    evt.preventDefault();
    // this.client.requestCode();
    let redirectURL = new URL(this.data.get('redirect-url'))
    redirectURL.searchParams.append("referrer", window.location.pathname)
    window.location = redirectURL.toString()
  }

  authCallback(response) {
    const csrfToken = document.getElementsByName("csrf-token")[0].content;

    fetch(this.data.get('callback-url'), {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(response),
    })
      .then((response) => response.json())
      .then((json) => {
        window.location.reload()
      })
  }
}
