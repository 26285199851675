import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this;
    componentHandler.upgradeAllRegistered();

    // Get detail value
    this.detailValue = this.element.dataset.detailValue;

    // Get dependent field ID
    this.dependentFieldId = this.element.dataset.detailDependentFieldId;

    // Get list of dependent field IDs
    const dependentFieldsIds = this.element.dataset.detailDependentFieldsId;
    this.dependentFieldsIds = dependentFieldsIds ? dependentFieldsIds.split(',') : [];

    // Call the method to show/hide fields on connect
    if (dependentFieldsIds && dependentFieldsIds.length > 0) {
      console.log('Dependent Fields IDs:', this.dependentFieldsIds);
      this.updateDependentFields();
    }
  }

  updateDependentFields() {
    this.dependentFieldsIds.forEach(id => {
      const field = document.querySelector(`[data-detail-id="${id}"]`);
      if (field) {
        const dependentValue = field.dataset.detailDependentFieldValue;
        
        console.log(`Field with ID ${id} has dependent value:`, dependentValue);
        console.log(`Field with ID ${id} should match:`, this.detailValue);
        console.group(`Show or hide? ${dependentValue === this.detailValue}`);
        // Show/hide the field based on the match
        if (dependentValue === this.detailValue) {
          $(field).show(500)
        } else {
          $(field).hide(500)
        }
      } else {
        console.warn(`Field with ID ${id} not found`);
      }
    });
  }
  
  toggle(event) {
    const icon = event.target
    const passwordFieldId = icon.getAttribute("data-password-field-id")
    const passwordField = document.getElementById(passwordFieldId)
    const type = passwordField.getAttribute("type") === "password" ? "text" : "password"
    passwordField.setAttribute("type", type)
    this.updateIcon(icon, type)
  }

  updateIcon(icon, type) {
    icon.textContent = type === "password" ? "show" : "hide"
  }
}
