import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
  }

  connect() {
    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this;
    console.log("Connecting notes list controller...")
  }

  disconnect() {
    console.log("Disconnecting notes list list controller...")
  }

  loadMore() {
    console.log("Clicked add more")
  }

}
