import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    console.log("Initializing detail-form controller")
  }

  connect() {
    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this;
    console.log("Connecting job form with parent " + this.data.get("parent-id") + "...")
  }

  disconnect() {
    console.log("Disconnecting detail form...")
  }

  showItemForm() {
    console.log("Showing item form")
    $(this.element).find(".addItemCTA").hide()
    var form = $(this.element).find(".itemForm");
    form.show()
    form.find('textarea')[0].focus();
  }

  hideItemForm() {
    console.log("Hiding item form")
    $(this.element).find(".addItemCTA").show()
    $(this.element).find(".itemForm").hide()
    this.resetForm();
  }

  resetForm() {
    var form = $(this.element)[0];
    console.log(form);
    form.reset();
  }

  postSuccess(event) {
    console.log("in postSuccess")
    this.resetForm();
    showToast("Detail added");
    hookupJavascript();
    // this.showItemForm();
  }

}
