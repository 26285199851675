import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "item", "list" ]

  initialize() {
    console.log("Initializing application controller")
  }

  distributeDirty (event) {
    return;
    console.log(event)
    var dirtyItemId = event.detail['item']
    var dirtyParentId = event.detail['parent']
    console.log("Distribute dirty for ids: " + dirtyItemId + " and " + dirtyParentId)
    // console.log(dirtyItemId)
    // console.log("There are this many items: ")
    // console.log(this.itemTargets)
    var that = this;
    this.itemTargets.forEach(function(it, idx) {
      if (it.dataset.itemId == dirtyItemId) {
        it.item.dirty(event);
      }
    })
    this.listTargets.forEach(function(it, idx) {
      console.log("Testing " + it.dataset.itemId + " and " + dirtyParentId)
      if (it.dataset.itemId == dirtyParentId) {
        console.log("Dirty parent!")
        it.item.dirty(event);
      }
    })
  }

}
