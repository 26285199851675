import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
  }

  connect() {
    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this;

    const email = this.element.dataset.email

    fetch(`/users/${btoa(email)}/avatar`)
      .then((r) => {
        if (r.ok)
          return r.text()
      })
      .then((html) => {
        if (html)
          this.element.innerHTML = html
      })
  }
}
