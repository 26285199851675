$(document).on('ajax:send', '.remote-modal-content', function(event) {
  if ($('#modal').is(':visible')) {
    console.log("Modal is already open, skipping...")
  } else {
    console.log("Open modal, set up spinner")
    $('#modal .modal-dialog').html(
      '<div class="modal-dialog"><div class="modal-content"><div class="text-center"><div class="mdl-spinner mdl-js-spinner is-active mt-5 mb-5"></div></div></div></div>')
    componentHandler.upgradeAllRegistered();
    $('#modal').modal('show')

    // hack: required because of the hack used on close
    $(".modal-backdrop").show()
    $('#modal').show()
  }
});

$(document).on('ajax:complete', '.remote-modal-content', function(event) {
  console.log("Add modal content")
  let content = event.detail[0].responseText
  $('#modal .modal-dialog').replaceWith(content)
  setTimeout(function() {
    let autofocusInput = $('#modal .autofocus')
    if (autofocusInput.length > 0) {
      autofocusInput[0].focus();
    }
  }, 500);
  hookupJavascript();
  console.log("Done");
});

$(document).on('ajax:send', '.remote-sidebar-content', function(event) {
  var spinner = '<div class="text-center"><div class="mdl-spinner mdl-js-spinner is-active mt-5 mb-5"></div></div>'
  // $('#item-preview').html(spinner);
  componentHandler.upgradeAllRegistered();
  $('#item-preview').addClass('active');
});

$(document).on('ajax:complete', '.remote-sidebar-content', function(event) {
  console.log("Add sidebar content")
  let content = event.detail[0].responseText
  $('#item-preview').html(content);
  hookupJavascript();
  console.log("Done");
});