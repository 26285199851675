import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['form', 'template', 'container', 'emailField']

  connect() {
    this.emails = []
  }

  toggle(event) {
    event.preventDefault();
    this.formTarget.classList.toggle("hidden")
    this.emailFieldTarget.focus()
  }

  addFromField(event) {
    event.preventDefault();
    if (this.emailFieldTarget.checkValidity()) {
      this.add(this.emailFieldTarget.value)
      this.emailFieldTarget.value = '';
      this.emailFieldTarget.focus()
    }
  }

  addQuick(event) {
    event.preventDefault();

    this.add(event.currentTarget.dataset.email)
  }

  add(email) {
    if (!email || this.emails.includes(email))
      return

    this.emails.push(email)

    const name = email.split("@")[0]
    const content = this.templateTarget.innerHTML
      .replace(/placeholder-email/g, email)
      .replace(/placeholder/g, name)

    this.containerTarget.innerHTML = this.containerTarget.innerHTML + content
    const chipIcon = this.containerTarget.querySelector(`#name-share-${name}`)
    chipIcon.innerText = name[0].toUpperCase()
  }

  remove(event) {
    event.preventDefault();
    const target = event.currentTarget;
    const parent = target.parentElement;
    const email = parent.querySelector("[data-email]").dataset.email
    this.emails = this.emails.filter((e) => e != email)
    parent.remove()
  }
}
