document.addEventListener("turbo:load", () => {
  $('.ui.standard-search')
    .search({
      apiSettings: {
        url: '/jobs/search/?q={query}'
      },
      type: "job",
      templates: {
        job: function(response) {
          return response.results.map((r) => r.results)
        }
      }
    });

    $('.ui.dataroom-search')
    .search({
      apiSettings: {
        url: '/jobs/search/?q={query}&treatment=dataroom'
      },
      type: "job",
      templates: {
        job: function(response) {
          return response.results.map((r) => r.results)
        }
      }
    });
});
