import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
  }

  connect() {
    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this;
    console.log("Connecting...")
  }

  disconnect() {
    console.log("Disconnecting...")
  }

  dispatchDirty() {
    return;
    var itemId = this.data.get("item-id");
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("item-dirty", true, true, {item: itemId})
    this.element.dispatchEvent(event)
  }

  dirty() {
    return;
    console.log("I'm dirty!")
    this.dispatchDirty();
    this.refresh();
  }

  refresh() {
    var itemId = this.data.get("item-id");
    var that = this;
    // TODO: This is not working after editing a filename in a modal
    $.ajax({
      url: '/jobs/' + itemId + '/attachments',
      success: function(result) {
        console.log($(this.element));
        $(that.element).replaceWith(result);
        hookupJavascript();
    }});
  }

}
