import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
  }

  connect() {
    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this;
    console.log("Connecting workspace controller...")
  }

  disconnect() {
    console.log("Disconnecting workspace controller...")
  }

  load() {
    var workspaceId = this.data.get("id");
    var inbox = this.data.get("inbox") !== null
    var templates = this.data.get("templates") !== null
    console.log("Load workspace id: " + workspaceId)

    $('.jobrow').remove();

    // for bootstrap-styled elements
    $('#workspace .card').html('<div class="text-center mt-3 mb-3"><div class="mdl-spinner mdl-js-spinner is-active"></div></div>')
    $('#workspace-right .card').html('<div class="text-center mt-3 mb-3"><div class="mdl-spinner mdl-js-spinner is-active"></div></div>')

    // for MDL-styled elements
    $('#workspace-right .mdl-card__supporting-text').html('<div class="text-center mt-3 mb-3"><div class="mdl-spinner mdl-js-spinner is-active"></div></div>')
    $('#workspace-right .mdl-card__supporting-text').html('<div class="text-center mt-3 mb-3"><div class="mdl-spinner mdl-js-spinner is-active"></div></div>')

    componentHandler.upgradeAllRegistered();

    $.ajax({
      url: '/collections/' + workspaceId + `?inbox=${inbox}&templates=${templates}`,
      success: function(result) {
        $('#workspace').html(result)
        hookupJavascript();
    }});

    $.ajax({
      url: '/collections/' + workspaceId + '/header',
      success: function(result) {
        $('#mainItemHeader').html(result)
        hookupJavascript();
      }
    })

    $.ajax({
      url: '/jobs/' + workspaceId + '/work_logs',
      success: function(result) {
        $('#notes-container').html(result)
        hookupJavascript();

        if ($(window).width() < 844) {
          $('#workspace')[0].scrollIntoView({behavior: 'smooth', alignto: 'top'});
        }
    }});

    $.ajax({
      url: '/jobs/' + workspaceId + '/attachments',
      success: function(result) {
        $('#attachments-container').html(result)
        hookupJavascript();
    }});

    $.ajax({
      url: '/collections/' + workspaceId + '/chat',
      success: function(result) {
        $('#chats-container').html(result)
        hookupJavascript();
      }
    });

    $.ajax({
      url: '/jobs/' + workspaceId + '/details?edit=true',
      success: function(result) {
        $('#details-container').html(result)
        hookupJavascript();
      }
    })


  }

}
