import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    console.log("Initializing file-form controller")
  }

  connect() {
    // see https://leastbad.com/stimulus-power-move
    this.element[this.identifier] = this;
    console.log("Connecting file form with parent " + this.data.get("parent-id") + "...")
  }

  disconnect() {
    console.log("Disconnecting file form controller...")
  }

  showFileForm() {
    console.log("Showing file form")
    $(this.element).find(".addFileCTA").hide()
    $(this.element).find(".cancelFileCTA").show()
    var form = $(this.element).find("#add-files-form");
    form.show()
  }

  hideFileForm() {
    console.log("Hiding item form")
    $(this.element).find(".addFileCTA").show()
    $(this.element).find(".cancelFileCTA").hide()
    $(this.element).find("#add-files-form").hide()
    this.resetForm();
  }

  resetForm() {
    var form = $(this.element).find('form')[0];
    form.reset();
    let additionalFields = $(this.element).find(".collapse.show")
    if (!!form.job_template)
      form.job_template.selectedIndex = 0
    additionalFields.collapse("hide")
  }

  postSuccess(event) {
    console.log("in postSuccess")
    this.resetForm()

    // add the new html to the collection; is there a better way with Stimulus?
    var eventDetails = event.detail[2]
    var row = eventDetails.responseText
    var jobs_table_id = "#jobs-table-" + this.data.get("parent-id") + '-' + this.data.get("template-id")
    console.log("jobs_table_id: " + jobs_table_id)
    var jobsTable = $(jobs_table_id)
    $(jobsTable)[0].insertAdjacentHTML("afterbegin", row)
    hookupJavascript();

    // Increment collection count
    var element = $( ".collection.active" )
    if (element.data("job-id") == this.data.get("parent-id")) {
      count = Number( element.find( ".badge" )[0].innerHTML );
      $( ".collection.active" ).find( ".badge" )[0].innerHTML = count + 1;
    }
  }

}
