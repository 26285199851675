$(document).ready(() => {

//   $(document).on("click", ".fixed.add-button", function(event) {
//     let job_id = event.currentTarget.getAttribute("data-job-id")
//     let input = $('form[data-job-id="' + job_id + '"] textarea')
//     let tabs = input[0].closest(".mdl-tabs")
//     let link = $(tabs).find(".mdl-tabs__tab-bar > a")[0];
//     link.click();
// //    input[0].scrollIntoView({behavior: 'smooth'});
//     $('a[name="buttonAnchor"]')[0].scrollIntoView({behavior: 'smooth'});
//     setTimeout(function() {
//        $('#add-item-form-' + job_id)[0]["job-form"].showItemForm()
//      }, 500);
//   })

  if ('ontouchstart' in window) {
    $(document).on('focus', 'textarea,input,select', function() {
        $('.fixed').hide();
    }).on('blur', 'textarea,input,select', function() {
        $('.fixed').show();
    });
  }

})
